var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"26c51e36bc17e02e154fc7c91e55756138678e84"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://260b8c5bc9ff47b79748e6703d65ef02@o359384.ingest.sentry.io/6094206",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new CaptureConsole({
      levels: ["error", "debug", "assert"],
    }),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        "api.givemomentum.com",
      ],
    }),
  ],
});
