import { AxiosResponse } from "axios";
import {
  deserializerFormConfigRaw,
  DonationFormConfig,
} from "donation-form/src/utils/api-cms";
import LogRocket from "logrocket";
import { useRouter } from "next/router";
import { createContext, useContext } from "react";
import { AxiosService } from "src/axios";
import { nextjs, urls } from "src/constants";
import { useFastStats } from "src/hooks/useFastStats";
import { useInit } from "src/hooks/useInit";
import { useNotifications } from "src/hooks/useNotifications";
import { usePaymentMethods } from "src/hooks/usePaymentMethods";
import { usePledges } from "src/hooks/usePledges";
import { getUrlParam } from "src/hooks/useRenderWithFormData";
import { useStateVar } from "src/hooks/useStateVar";
import { useWithdrawals } from "src/hooks/useWithdrawals";
import { Sentry } from "src/sentry";

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

export const AuthContext = createContext({} as ReturnType<typeof useProvideAuth>);

function useProvideAuth() {
  const hooks = {
    router: useRouter(),
    notifications: useNotifications(),
    pledges: usePledges(),
    paymentMethods: usePaymentMethods(),
    withdrawals: useWithdrawals(),
    fastStats: useFastStats(),
  };

  const state = {
    user: useStateVar<User | null>(storage.getUser()),
  };

  useInit(async () => {
    if (state.user.val) {
      await refreshUserData();
    }

    // todo remove page specific logic from the hook
    const loginToken = getUrlParam("token");
    const passwordSetToken = getUrlParam("password-token");
    if (
      loginToken &&
      !urls.isCurrentPage(urls.account.setPassword) &&
      !urls.isCurrentPage(urls.account.profile)
    ) {
      const userAuthed = await loginWithToken(loginToken);
      if (userAuthed) {
        if (
          (urls.isCurrentPage(urls.donor.accountPayment) ||
            urls.isCurrentPage(urls.donor.pledges)) &&
          passwordSetToken
        ) {
          saveUserToContextAndLocalStorage({
            ...userAuthed,
            isEnforcePasswordSet: true,
            passwordSetToken,
          });
        }
      } else {
        hooks.notifications.add(
          "info",
          "Your login token has expired, please login to continue or request a password reset link below",
        );
        let nextPath = `${urls.donor.login}?next_path=${window.location.pathname}`;
        const emailToPrefill = getUrlParam("email");
        if (emailToPrefill) {
          nextPath += `&email=${encodeURIComponent(emailToPrefill)}`;
        }
        hooks.router.push(nextPath);
      }
    }

    if (state.user.val?.token) {
      await fetchAccountData(state.user.val);
    }
  });

  async function fetchAccountData(userCurrent: User) {
    await Promise.all([
      hooks.pledges.fetchAll(),
      hooks.withdrawals.fetchAll(userCurrent.pk),
      hooks.paymentMethods.fetch(),
      hooks.fastStats.fetch(userCurrent.pk),
    ]);
  }

  async function refreshUserData(user?: User) {
    try {
      const resEmail = await AxiosService.get("/users/is-email-verified");
      const resUser: AxiosResponse<UserRaw> = await AxiosService.get("/users/me");

      saveUserToContextAndLocalStorage({
        ...(user ?? state.user.val)!,
        ...serializeUser(resUser.data),
        isEmailVerified: resEmail.data,
      });
      // todo also store email, name, and last form id
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  async function loginWithToken(token: string): Promise<User | null> {
    try {
      clearUserData();
      const resUser: AxiosResponse<UserLoginRes> = await AxiosService.get(
        `/users/login/token/${token}`,
      );
      return saveUserAndFetchServerData({ userRes: resUser.data });
    } catch (error: any) {
      Sentry.captureException(error);
      return null;
    }
  }

  async function login(credentials: UserCreds) {
    try {
      clearUserData();
      const res: AxiosResponse<UserLoginRes> = await AxiosService.post(
        "dj-rest-auth/login/",
        credentials,
      );
      await saveUserAndFetchServerData({ userRes: res.data, isFormHijacked: false });
    } catch (error: any) {
      if (
        error.response?.data?.non_field_errors &&
        error.response?.data?.non_field_errors[0] ===
          "Unable to log in with provided credentials."
      ) {
        throw new AuthError("credentials");
      } else {
        throw error;
      }
    }
  }

  async function setPasswordAndLogin(password: string, token?: string): Promise<void> {
    try {
      clearUserData();
      const resUser: AxiosResponse<UserLoginRes> = await AxiosService.post(
        "/users/password-set-and-login/",
        {
          password,
          token: token ?? getUrlParam("token"),
        },
      );
      const userNew = await saveUserAndFetchServerData({ userRes: resUser.data });
      await saveUserToContextAndLocalStorage({ ...userNew, isEnforcePasswordSet: false });
    } catch (error: any) {
      if (error.http_status === 403) {
        if (
          error.code !== "password_token_invalid" ||
          error.code !== "password_token_expired"
        ) {
          Sentry.captureException(error);
        }
      } else {
        Sentry.captureException(error);
      }
      throw error;
    }
  }

  async function sendPasswordResetEmail() {
    await AxiosService.post("/users/password-reset/", { email: state.user.val?.email });
  }

  async function resendEmailVerification() {
    await AxiosService.post("/dj-rest-auth/registration/resend-email/", {
      email: state.user.val?.email,
    });
  }

  async function updateUserName(data: { firstName: string; lastName?: string }) {
    await AxiosService.patch("dj-rest-auth/user/", {
      first_name: data.firstName,
      last_name: data.lastName,
    });
  }

  async function updateUserTip(data: { tip: number }) {
    await AxiosService.patch("users/me", { tip_percent: data.tip });
  }

  async function signup(credentials: UserCreds) {
    const res: AxiosResponse<UserLoginRes> = await AxiosService.post(
      "dj-rest-auth/registration/",
      {
        email: credentials.email,
        password1: credentials.password,
        password2: credentials.password,
      },
    );
    await saveUserAndFetchServerData({ userRes: res.data });
  }

  function clearUserData() {
    state.user.set(null);
    storage.setUser(null);
    setAxiosJwtToken(null);
  }

  // todo move out form hijack logic as it isn't related, or isn't supposed to be

  async function hijackForm(
    formPk: number | string,
    token: string,
  ): Promise<DonationFormConfig> {
    const user = await loginWithToken(token);
    setAxiosJwtToken(user!.token);
    const resForm = await AxiosService.get(`donation-forms/${formPk}`);
    const form = await deserializerFormConfigRaw(resForm.data);
    saveUserToContextAndLocalStorage({ ...user!, formPk: form.id, isFormHijacked: true });
    return form;
  }

  async function createOrFetchDonationForm(
    userCurrent?: User | null,
  ): Promise<DonationFormConfig> {
    try {
      let res: AxiosResponse;
      if (state.user.val?.formPk) {
        res = await AxiosService.get(`/donation-forms/${state.user.val.formPk}`);
      } else {
        res = await AxiosService.post("/donation-forms/create-or-fetch-latest");
      }
      const form = await deserializerFormConfigRaw(res.data);
      if (!form.description) {
        form.description =
          "Write a sentence or two here describing your organization’s mission and how the donor’s gift can have an impact. It’s often best to keep this brief, since at this stage in the donation the donor should already be familiar with your work.";
      }
      if (state.user.val?.token) {
        saveUserToContextAndLocalStorage({ ...state.user.val, formPk: form.id });
      } else if (userCurrent) {
        saveUserToContextAndLocalStorage({ ...userCurrent, formPk: form.id });
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw new AuthError("invalid_user");
      }
      return form;
    } catch (error: any) {
      if (error?.response?.data?.code === "validation_error") {
        for (const resError of error.response?.data?.detail ?? []) {
          if (resError?.code === "no_charity") {
            throw new AuthError("no_charity");
          }
        }
      }
      throw error;
    }
  }

  async function saveUserAndFetchServerData(args: {
    userRes: UserLoginRes;
    isFormHijacked?: boolean;
  }): Promise<User> {
    LogRocket.identify(args.userRes.user.email, {
      name: `${args.userRes.user.first_name} ${args.userRes.user.last_name}`,
    });
    const userNew: User = {
      ...(serializeUser(args.userRes.user) as User),
      token: state.user.val?.token ?? args.userRes.access_token!,
      formPk: null,
      isEmailVerified: false,
      isFormHijacked: args.isFormHijacked ?? false,
    };
    saveUserToContextAndLocalStorage(userNew);
    fetchAccountData(userNew);
    refreshUserData(userNew);
    return userNew;
  }

  function serializeUser(userRaw: UserRaw): Partial<User> {
    return {
      pk: userRaw.id ?? userRaw.pk,
      email: userRaw.email,
      firstName: userRaw.first_name,
      lastName: userRaw.last_name,
      tipPercent: userRaw.tip_percent,
      lastLogin: userRaw.last_login ? new Date(userRaw.last_login) : undefined,
      isAllowToCreateDonationForms: userRaw.is_allow_to_create_donation_forms,
    };
  }

  function saveUserToContextAndLocalStorage(user: User) {
    state.user.set(user);
    storage.setUser(user);
    Sentry.setContext("user", user);
    Sentry.setUser(user);
    setAxiosJwtToken(user.token);
  }

  function logout() {
    state.user.set(null);
    storage.setUser(null);
    setAxiosJwtToken(null);
  }

  async function deleteUser() {
    await AxiosService.delete(`users/${state.user.val!.pk}`);
  }

  /* eslint-disable object-shorthand */
  return {
    user: state.user.val,
    isPardesOrg: () => state.user.val?.charity?.id === 16160,
    pledges: hooks.pledges,
    paymentMethods: hooks.paymentMethods,
    fastStats: hooks.fastStats,
    withdrawals: hooks.withdrawals,
    isAuthenticated: Boolean(state.user.val?.token),
    isDonorPortal: hooks.router.pathname.includes("/donor/"),
    isFormHijacked: Boolean(state.user.val?.isFormHijacked),
    getFormUrl: () => `/donation-forms/${state.user.val?.formPk}/`,
    login: login,
    loginWithToken: loginWithToken,
    signup: signup,
    logout: logout,
    updateUserName: updateUserName,
    updateUserTip: updateUserTip,
    refreshUserData: refreshUserData,
    resendEmailVerification: resendEmailVerification,
    createOrFetchDonationForm: createOrFetchDonationForm,
    deleteUser: deleteUser,
    hijackForm: hijackForm,
    setPasswordAndLogin: setPasswordAndLogin,
    sendPasswordResetEmail: sendPasswordResetEmail,
  };
}

module storage {
  export function setUser(user: User | null) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  export function getUser(): User | null {
    if (nextjs.isSSR) {
      return null;
    }
    const user: User | null = JSON.parse(localStorage.getItem("user") as string);
    if (user) {
      setAxiosJwtToken(user.token);
    }
    return user;
  }
}

// todo move somehow into another hook to make dependency explicit, avoid global var mess
// useUser hook? which everybody would use include useAuth
function setAxiosJwtToken(token: string | null) {
  if (token) {
    AxiosService.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else {
    AxiosService.defaults.headers.common = {};
  }
}

export interface UserCreds {
  email: string;
  password: string;
}

interface UserLoginRes {
  access_token?: string;
  user: UserRaw;
}

interface UserRaw {
  email: string;
  id?: number;
  pk?: number;
  charity: {
    id: number;
  };
  tip_percent: number;
  first_name: string;
  last_name: string;
  created_at: string;
  utc_offset: number;
  currency: string;
  last_login?: string;
  is_allow_to_create_donation_forms: boolean;
}

export interface User {
  pk: number;
  charity: {
    id: number;
  };
  email: string;
  token: string;
  firstName: string | null;
  lastName: string | null;
  tipPercent: number;
  formPk: number | null;
  isEmailVerified: boolean;
  isFormHijacked: boolean;
  isEnforcePasswordSet?: boolean;
  passwordSetToken?: string;
  lastLogin?: Date;
  isAllowToCreateDonationForms: boolean;
}

export class AuthError extends Error {
  code: codes;

  constructor(code: codes, ...args) {
    super(...args);
    this.code = code;
  }
}

type codes = "credentials" | "no_charity" | "invalid_user";
